import React, { useEffect, useState } from "react";

const StreamingClasses = () => {
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      const token = localStorage.getItem("token");
      const res = await fetch("/admin/classes", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      setClasses(data);
    };
    fetchClasses();
  }, []);

  return (
    <div>
      <h2>Streaming Classes</h2>
      <table>
        <thead>
          <tr>
            <th>Class Name</th>
            <th>Teacher</th>
            <th>Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {classes.map((cls) => (
            <tr key={cls.id}>
              <td>{cls.className}</td>
              <td>{cls.teacher}</td>
              <td>{cls.time}</td>
              <td>
                {/* Add buttons for edit/delete */}
                <button>Edit</button>
                <button>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StreamingClasses;
