import React, { useEffect, useState } from "react";

const ManageAttendance = () => {
  const [attendance, setAttendance] = useState([]);

  useEffect(() => {
    const fetchAttendance = async () => {
      const token = localStorage.getItem("token");
      const res = await fetch("/admin/attendance", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      setAttendance(data);
    };
    fetchAttendance();
  }, []);

  return (
    <div>
      <h2>Manage Attendance</h2>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {attendance.map((record) => (
            <tr key={record.id}>
              <td>{record.studentName}</td>
              <td>{record.date}</td>
              <td>{record.status}</td>
              <td>
                {/* Add buttons for edit/delete */}
                <button>Edit</button>
                <button>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageAttendance;
