import React, { useEffect, useState } from "react";

const ManageResults = () => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      const token = localStorage.getItem("token");
      const res = await fetch("/admin/results", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      setResults(data);
    };
    fetchResults();
  }, []);

  return (
    <div>
      <h2>Manage Results</h2>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            <th>Subject</th>
            <th>Score</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={result.id}>
              <td>{result.studentName}</td>
              <td>{result.subject}</td>
              <td>{result.score}</td>
              <td>
                {/* Add buttons for edit/delete */}
                <button>Edit</button>
                <button>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageResults;
