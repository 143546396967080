import React from "react";
import TeacherList from "../components/TeacherList";

function TeachersPage() {
  return (
    <div>
      <h1>Teachers Management</h1>
      <TeacherList />
    </div>
  );
}

export default TeachersPage;
