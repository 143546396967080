// src/components/Footer.js
import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="max-w-6xl mx-auto px-4 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* About Section */}
        <div className="mb-6">
          <h3 className="text-lg font-bold mb-3">About Ogun Science Academy</h3>
          <p className="text-gray-400">
            Ogun Science Academy is dedicated to providing a high-quality
            education that prepares students to excel in science and technology.
            Our mission is to foster innovation and curiosity in the minds of
            future leaders.
          </p>
        </div>

        {/* Quick Links Section */}
        <div className="mb-6">
          <h3 className="text-lg font-bold mb-3">Quick Links</h3>
          <ul className="space-y-2">
            <li>
              <a
                href="/about"
                className="text-gray-400 hover:text-white transition"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="/programs"
                className="text-gray-400 hover:text-white transition"
              >
                Our Programs
              </a>
            </li>
            <li>
              <a
                href="/contact"
                className="text-gray-400 hover:text-white transition"
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="/blog"
                className="text-gray-400 hover:text-white transition"
              >
                Latest News
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Information Section */}
        <div>
          <h3 className="text-lg font-bold mb-3">Get In Touch</h3>
          <p className="text-gray-400 mb-2">
            <strong>Email:</strong> info@ogunscienceacademy.com.ng
          </p>
          <p className="text-gray-400 mb-2">
            <strong>Phone:</strong> +234 803-369-2734
          </p>
          <p className="text-gray-400">
            <strong>Address:</strong> Abeokuta, Ogun State State
          </p>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="border-t border-gray-700 mt-8 py-6">
        <div className="flex justify-center space-x-6">
          <a
            href="#"
            className="text-gray-400 hover:text-white transition"
            aria-label="Facebook"
          >
            <FaFacebookF size={20} />
          </a>
          <a
            href="https://twitter.com"
            className="text-gray-400 hover:text-white transition"
            aria-label="Twitter"
          >
            <FaTwitter size={20} />
          </a>
          <a
            href="https://instagram.com"
            className="text-gray-400 hover:text-white transition"
            aria-label="Instagram"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://linkedin.com"
            className="text-gray-400 hover:text-white transition"
            aria-label="LinkedIn"
          >
            <FaLinkedinIn size={20} />
          </a>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="bg-gray-800 text-center py-4">
        <p className="text-gray-500">
          &copy; {new Date().getFullYear()} Ogun Science Academy. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
