// src/redux/teachersSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {
    addTeacher: (state, action) => {
      state.list.push(action.payload);
    },
    removeTeacher: (state, action) => {
      state.list = state.list.filter(
        (teacher) => teacher.id !== action.payload
      );
    },
  },
});

export const { addTeacher, removeTeacher } = teachersSlice.actions;

export default teachersSlice.reducer;
