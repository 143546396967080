// src/pages/ContactUs.js
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ContactUs = () => {
  return (
    <div className="pt-10">
      <Navbar />
      <section className="bg-gradient-to-r from-green-50 to-blue-50 min-h-screen py-12">
        <div className="max-w-4xl mx-auto px-6">
          <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
            Contact Us
          </h1>
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <h2 className="text-3xl font-semibold text-gray-800 mb-6">
              Get in Touch
            </h2>
            <p className="text-gray-700 mb-6">
              We would love to hear from you! Whether you have questions,
              feedback, or just want to connect, please reach out to us using
              the contact information below.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-green-100 p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold text-green-700 mb-4">
                  Contact Information
                </h3>
                <p className="text-gray-700 mb-2">
                  <strong>Email:</strong> info@ogunscienceacademy.ng
                </p>
                <p className="text-gray-700 mb-2">
                  <strong>Phone:</strong> +234 803-369-2734
                </p>
                <p className="text-gray-700">
                  <strong>Address:</strong> Abeokuta, Ogun State
                </p>
              </div>
              <div className="bg-blue-100 p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-semibold text-blue-700 mb-4">
                  Office Hours
                </h3>
                <p className="text-gray-700 mb-2">
                  <strong>Monday - Friday:</strong> 9:00 AM - 5:00 PM
                </p>
                <p className="text-gray-700 mb-2">
                  <strong>Saturday:</strong> 10:00 AM - 2:00 PM
                </p>
                <p className="text-gray-700">
                  <strong>Sunday:</strong> Closed
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-3xl font-semibold text-gray-800 mb-6">
              Drop Us a Message
            </h2>
            <form className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-gray-700 mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-gray-700 mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg"
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
