import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import your actual images
import image1 from "../images/school_img_1.jpg";
import image2 from "../images/school_img_2.jpg";
import image3 from "../images/school_img_3.jpg";
import image4 from "../images/school_img_4.jpg";
import image5 from "../images/school_img_5.jpg";
import image6 from "../images/school_img_6.jpg";
import image7 from "../images/school_img_7.jpg";
import image8 from "../images/school_img_8.jpg";
import image9 from "../images/school_img_9.jpg";
import image10 from "../images/school_img_10.jpg";
import image11 from "../images/school_img_11.jpg";
import image12 from "../images/school_img_12.jpg";
import image13 from "../images/school_img_13.jpg";
import image14 from "../images/school_img_14.jpg";
import image15 from "../images/school_img_15.jpg";
import image16 from "../images/school_img_16.jpg";

const images = [
  { src: image1, caption: "Event 1: Science Fair" },
  { src: image2, caption: "Event 2: Sports Day" },
  { src: image3, caption: "Event 3: Art Exhibition" },
  { src: image4, caption: "Event 4: Graduation Ceremony" },
  { src: image5, caption: "Event 5: Music Festival" },
  { src: image6, caption: "Event 6: Field Trip" },
  { src: image7, caption: "Event 7: Community Service" },
  { src: image8, caption: "Event 8: Cultural Day" },
  { src: image9, caption: "Event 8: Cultural Day" },
  { src: image10, caption: "Event 8: Cultural Day" },
  { src: image11, caption: "Event 8: Cultural Day" },
  { src: image12, caption: "Event 8: Cultural Day" },
  { src: image13, caption: "Event 8: Cultural Day" },
  { src: image14, caption: "Event 8: Cultural Day" },
  { src: image15, caption: "Event 8: Cultural Day" },
  { src: image16, caption: "Event 8: Cultural Day" },
];

function GalleryPage() {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Gallery Section Header */}
      <div className="py-20 px-4 bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 text-white text-center">
        <h1 className="text-6xl font-extrabold mb-6">Gallery</h1>
        <p className="text-xl mb-4 max-w-3xl mx-auto">
          Explore the vibrant life at Ogun Science Academy! These images capture
          the essence of our community, events, and achievements.
        </p>
        <p className="text-lg max-w-2xl mx-auto">
          Scroll through and click on any photo to view it in full detail.
        </p>
      </div>

      {/* Image Grid */}
      {/* You can update the color here */}
      <div className="py-16 px-4 bg-blue-50">
        {" "}
        {/* New background color */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {images.map((image, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer group"
              onClick={() => handleImageClick(image)}
            >
              <img
                src={image.src}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-64 object-cover group-hover:scale-105 transition-transform duration-500"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-0 group-hover:opacity-80 transition-opacity duration-300"></div>
              <p className="absolute bottom-4 left-4 text-white text-lg font-semibold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {image.caption}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for Image Details */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 overflow-y-auto">
          <div className="relative bg-white rounded-lg overflow-hidden shadow-lg p-6 max-w-3xl w-full">
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-700 hover:text-red-500 text-2xl font-bold"
            >
              &times;
            </button>
            <img
              src={selectedImage.src}
              alt="Selected"
              className="w-full h-auto max-h-screen rounded-lg mb-4 object-contain"
            />
            <p className="text-center text-xl font-semibold mb-4">
              {selectedImage.caption}
            </p>
            <button
              onClick={closeModal}
              className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default GalleryPage;
