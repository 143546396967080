// src/redux/classesSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const classesSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    addClass: (state, action) => {
      state.list.push(action.payload);
    },
    removeClass: (state, action) => {
      state.list = state.list.filter((cls) => cls.id !== action.payload);
    },
  },
});

export const { addClass, removeClass } = classesSlice.actions;

export default classesSlice.reducer;
