// src/pages/AdmissionPage.js
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AdmissionForm from "../components/AdmissionForm";

const AdmissionPage = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <div className="flex justify-center items-center py-20 px-4 bg-gradient-to-r from-green-500 to-blue-600 text-white">
        <div className="text-center max-w-2xl">
          <h1 className="text-5xl font-bold mb-6">
            Welcome to Ogun Science Academy
          </h1>
          <p className="text-lg mb-8">
            "Education is the key to unlocking the world, a passport to
            freedom." Join us at Ogun Science Academy to embark on your journey
            towards academic excellence and personal growth.
          </p>
          <p className="text-lg font-semibold">
            Admissions for the 2024 academic year are now open!
          </p>
        </div>
      </div>

      {/* Admission Form Section */}
      <div className="flex justify-center py-16 px-4 flex-grow">
        <div className="bg-white rounded-lg shadow-2xl p-10 w-full max-w-4xl">
          <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">
            Apply for Admission
          </h2>
          <p className="text-gray-600 text-center mb-8">
            Complete the form below to start your admission process at Ogun
            Science Academy. We are dedicated to offering a rich educational
            experience that prepares your child for a successful future.
          </p>

          {/* Admission Form */}
          <AdmissionForm />
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AdmissionPage;
