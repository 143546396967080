import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  FaAward,
  FaBookOpen,
  FaUsers,
  FaFlask,
  FaMicroscope,
  FaTrophy,
} from "react-icons/fa";

const AboutPage = () => {
  return (
    <div className="bg-gradient-to-r from-green-500 to-blue-500 min-h-screen pb-10">
      <div className="bg-gray-900 bg-opacity-70 min-h-screen">
        <Navbar />

        {/* Hero Section */}
        <div
          className="relative bg-cover bg-center h-96 flex items-center justify-center"
          style={{ backgroundImage: 'url("/images/science-banner.jpg")' }}
        >
          <h1 className="text-white text-5xl font-extrabold text-center bg-black bg-opacity-50 px-6 py-4 rounded-lg">
            Ogun Science Academy
          </h1>
        </div>

        {/* Welcome Address Section */}
        <section className="bg-white text-gray-800 shadow-lg rounded-lg p-8 mx-auto mt-10 max-w-7xl">
          <div className="text-center">
            <h2 className="text-4xl font-extrabold text-blue-600 mb-6">
              Welcome to Ogun Science Academy
            </h2>
            <p className="text-lg leading-relaxed mb-8">
              Welcome to <strong>Ogun Science Academy</strong>, located in
              Abeokuta, Ogun State. We are dedicated to nurturing the next
              generation of job creators, innovators, and leaders through
              rigorous science education and hands-on learning experiences.
            </p>
          </div>
          <div className="bg-gradient-to-r from-green-400 to-blue-400 text-white p-6 rounded-lg shadow-inner">
            <h3 className="text-3xl font-semibold mb-4">Our Mission</h3>
            <p className="leading-relaxed mb-6">
              To promote curiosity, creativity, and excellence in science and
              technology through engaging and innovative educational
              experiences, contributing to the nation's development of human
              resources.
            </p>
            <h3 className="text-3xl font-semibold mb-4">Our Vision</h3>
            <p className="leading-relaxed mb-6">
              To create a world where science is the key that opens doors of
              possibility, enabling minds to shape and transform the future.
            </p>
          </div>
        </section>

        {/* New Students Section */}
        <section className="bg-gray-100 text-gray-800 rounded-lg p-8 mx-auto mt-10 max-w-7xl">
          <div className="text-center">
            <h3 className="text-3xl font-semibold text-blue-600 mb-4">
              To Our Dear New Students
            </h3>
            <p className="leading-relaxed mb-4">
              Welcome to a world of exploration and discovery. At Ogun Science
              Academy, we encourage you to think critically, challenge
              assumptions, and cultivate a lifelong passion for learning.
            </p>
            <p className="leading-relaxed mb-4">
              Our programs are designed to inspire and challenge you, preparing
              you for prestigious universities and successful careers in STEM
              fields. Over the years, our alumni have gone on to make
              significant contributions in science, medicine, engineering, and
              innovation.
            </p>
            <div className="bg-white p-6 rounded-lg shadow-lg mt-6">
              <h4 className="text-2xl font-semibold text-gray-800 mb-4">
                We Expect:
              </h4>
              <ul className="text-left list-disc list-inside text-gray-700 leading-relaxed space-y-2">
                <li>
                  <strong>Dedication and hard work</strong>
                </li>
                <li>
                  <strong>Respect for others and the environment</strong>
                </li>
                <li>
                  <strong>Active engagement in school activities</strong>
                </li>
              </ul>
              <p className="leading-relaxed mt-6">
                Together, let’s make <strong>Ogun Science Academy</strong> a
                beacon of academic excellence and innovation.
              </p>
              <p className="mt-4 font-semibold text-gray-800">
                HON. S. A. T. OMONIYI
              </p>
              <p className="text-gray-600">Director of Studies</p>
            </div>
          </div>
        </section>

        {/* What Makes Ogun Science Academy Stand Out */}
        <section className="bg-white shadow-lg rounded-lg p-8 mt-10 max-w-7xl mx-auto">
          <h2 className="text-3xl font-semibold text-blue-600 mb-6 text-center">
            What Makes Ogun Science Academy Stand Out
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Academic Excellence */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner text-center">
              <FaAward className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Academic Excellence</h3>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Internationally aligned curriculum</li>
                <li>Highly qualified science educators</li>
                <li>Small class sizes for personalized learning</li>
              </ul>
            </div>

            {/* State-of-the-Art Facilities */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner text-center">
              <FaMicroscope className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">
                State-of-the-Art Facilities
              </h3>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Modern science labs with the latest technology</li>
                <li>Cutting-edge coding and robotics labs</li>
                <li>Expansive libraries and learning centers</li>
              </ul>
            </div>

            {/* Innovative Programs */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner text-center">
              <FaFlask className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Innovative Programs</h3>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Robotics and coding clubs</li>
                <li>Science fairs and competitions</li>
                <li>Internship opportunities with industry partners</li>
              </ul>
            </div>

            {/* Holistic Development */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner text-center">
              <FaUsers className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Holistic Development</h3>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Character-building programs (leadership, teamwork)</li>
                <li>Extracurricular activities (sports, music, arts)</li>
                <li>Mentorship from successful alumni</li>
              </ul>
            </div>

            {/* Community Engagement */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner text-center">
              <FaBookOpen className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Community Engagement</h3>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Partnerships with local industries and organizations</li>
                <li>Service learning and community outreach programs</li>
                <li>Active Parent-Teacher Forum</li>
              </ul>
            </div>

            {/* Achievements */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner text-center">
              <FaTrophy className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-2xl font-bold mb-4">Our Achievements</h3>
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Consistently high graduation rates</li>
                <li>Multiple awards in national science competitions</li>
                <li>Alumni success in leading STEM industries</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Slogan, Tagline, Motto, and Core Values Section */}
        <section className="bg-white shadow-lg rounded-lg p-8 mt-10 max-w-7xl mx-auto">
          <h2 className="text-3xl font-semibold text-blue-600 mb-6 text-center">
            Our Philosophy
          </h2>

          <div className="text-center">
            <p className="text-xl font-semibold mb-4">
              Slogan: "Igniting Minds, Shaping Tomorrow"
            </p>
            <p className="text-xl font-semibold mb-4">
              Tagline: "Empowering Future Thinkers, Innovators, and Leaders"
            </p>
            <p className="text-xl font-semibold mb-4">
              Motto: "Excellence through Science"
            </p>
          </div>

          <div className="mt-8">
            <h3 className="text-2xl font-semibold text-center mb-4">
              Our Core Values
            </h3>
            <ul className="list-disc list-inside text-gray-700 leading-relaxed space-y-2">
              <li>Integrity and Excellence</li>
              <li>Critical Thinking and Innovation</li>
              <li>Curiosity and Lifelong Learning</li>
              <li>Teamwork and Leadership</li>
            </ul>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default AboutPage;
