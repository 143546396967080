import React from "react";

function ClassSchedule() {
  const classes = [
    { id: 1, class: "Math 101", time: "09:00 AM", teacher: "Mr. Anderson" },
    { id: 2, class: "English 101", time: "10:00 AM", teacher: "Mrs. Davis" },
    { id: 3, class: "Science 101", time: "11:00 AM", teacher: "Mr. Lee" },
  ];

  return (
    <div className="class-schedule">
      <h2>Class Schedule</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Class</th>
            <th>Time</th>
            <th>Teacher</th>
          </tr>
        </thead>
        <tbody>
          {classes.map((cls) => (
            <tr key={cls.id}>
              <td>{cls.id}</td>
              <td>{cls.class}</td>
              <td>{cls.time}</td>
              <td>{cls.teacher}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ClassSchedule;
