// src/redux/studentsSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    addStudent: (state, action) => {
      state.list.push(action.payload);
    },
    removeStudent: (state, action) => {
      state.list = state.list.filter(
        (student) => student.id !== action.payload
      );
    },
  },
});

export const { addStudent, removeStudent } = studentsSlice.actions;

export default studentsSlice.reducer;
