// src/pages/AdminDashboard.js

// import React from "react";
// import Navbar from "../components/Navbar";
// import Dashboard from "../components/Dashboard";

// function AdminDashboard() {
//   return (
//     <div>
//       <Navbar />
//       <h1>Admin Dashboard</h1>
//       <Dashboard />
//     </div>
//   );
// }

// export default AdminDashboard;

import React from "react";
import { Link, Outlet } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard">
      <nav>
        <ul>
          <li>
            <Link to="/admin/users">Manage Users</Link>
          </li>
          <li>
            <Link to="/admin/results">Manage Results</Link>
          </li>
          <li>
            <Link to="/admin/attendance">Manage Attendance</Link>
          </li>
          <li>
            <Link to="/admin/fees">Manage Fees</Link>
          </li>
          <li>
            <Link to="/admin/classes">Streaming Classes</Link>
          </li>
        </ul>
      </nav>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminDashboard;
