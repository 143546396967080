// src/pages/InternationalCoursesPage.js
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const courses = [
  {
    title: "English Language Program",
    description:
      "Enhance your English speaking, writing, and comprehension skills.",
    enrollmentLink: "#", // Replace with actual enrollment link
  },
  {
    title: "International Business Management",
    description:
      "Learn the fundamentals of global business strategies and practices.",
    enrollmentLink: "#",
  },
  {
    title: "Global Studies",
    description:
      "Explore cultural, political, and economic issues that affect the world.",
    enrollmentLink: "#",
  },
  {
    title: "STEM Courses",
    description:
      "Innovative programs in Science, Technology, Engineering, and Mathematics.",
    enrollmentLink: "#",
  },
  {
    title: "Cultural Exchange Program",
    description:
      "Experience diverse cultures through immersive exchange activities.",
    enrollmentLink: "#",
  },
  {
    title: "Art and Design",
    description:
      "Develop your artistic skills while exploring international art styles.",
    enrollmentLink: "#",
  },
];

function InternationalCoursesPage() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Navbar */}
      <Navbar />

      {/* Courses Section */}
      <div className="py-20 px-4 bg-gradient-to-r from-green-400 to-blue-500 text-white text-center">
        <h1 className="text-5xl font-bold mb-6">International Courses</h1>
        <p className="text-lg mb-8">
          Discover a world of learning at World Star Academy! Our international
          courses are designed to broaden your horizons and enhance your skills.
        </p>
      </div>

      {/* Course List */}
      <div className="py-16 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {courses.map((course, index) => (
            <a
              key={index}
              href={course.enrollmentLink}
              className="bg-white rounded-lg shadow-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-xl cursor-pointer"
            >
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                {course.title}
              </h2>
              <p className="text-gray-600">{course.description}</p>
              <p className="mt-4 text-blue-500 font-semibold">Enroll Now</p>
            </a>
          ))}
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default InternationalCoursesPage;
