// src/pages/RegisterPage.js

import React from "react";
import Navbar from "../components/Navbar";
import RegisterForm from "../components/RegisterForm";
import Footer from "../components/Footer";

function RegisterPage() {
  return (
    <div>
      <Navbar />
      <h1 className="mt-3">Register</h1>
      <RegisterForm />
      <Footer />
    </div>
  );
}

export default RegisterPage;
