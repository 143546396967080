import React, { useEffect, useState } from "react";

const ManageFees = () => {
  const [fees, setFees] = useState([]);

  useEffect(() => {
    const fetchFees = async () => {
      const token = localStorage.getItem("token");
      const res = await fetch("/admin/fees", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      setFees(data);
    };
    fetchFees();
  }, []);

  return (
    <div>
      <h2>Manage Fees</h2>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            <th>Fee Type</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {fees.map((fee) => (
            <tr key={fee.id}>
              <td>{fee.studentName}</td>
              <td>{fee.feeType}</td>
              <td>{fee.amount}</td>
              <td>{fee.status}</td>
              <td>
                {/* Add buttons for edit/delete */}
                <button>Edit</button>
                <button>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageFees;
