import React from "react";
import StudentList from "../components/StudentList";

function StudentsPage() {
  return (
    <div>
      <h1>Students Management</h1>
      <StudentList />
    </div>
  );
}

export default StudentsPage;
