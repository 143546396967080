import React from "react";
import ClassSchedule from "../components/ClassSchedule";

function ClassesPage() {
  return (
    <div>
      <h1>Class Management</h1>
      <ClassSchedule />
    </div>
  );
}

export default ClassesPage;
