// src/redux/store.js

import { configureStore } from "@reduxjs/toolkit";
import studentsReducer from "./studentsSlice";
import teachersReducer from "./teachersSlice";
import classesReducer from "./classesSlice";

export const store = configureStore({
  reducer: {
    students: studentsReducer,
    teachers: teachersReducer,
    classes: classesReducer,
  },
});

export default store;
