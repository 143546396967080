import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { FaHandsHelping, FaInfoCircle } from "react-icons/fa";

const SponsorPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic (e.g., send to API)
    console.log("Form submitted");
    toggleModal(); // Close modal after submission
  };

  return (
    <div className="bg-gradient-to-r from-green-300 via-blue-300 to-purple-300 min-h-screen pb-10">
      <div className="bg-white bg-opacity-80 min-h-screen">
        <Navbar />
        <div className="max-w-7xl mx-auto mt-10 p-6 text-gray-800">
          {/* Hero Section */}
          <h1 className="text-4xl font-bold text-center mb-8">
            Sponsorship Program
          </h1>
          <p className="text-lg text-center mb-12">
            Join us in empowering students through our sponsorship program. Your
            support can change lives!
          </p>

          {/* Program Overview Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <FaInfoCircle className="text-green-600 mr-2" />
              Program Overview
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Our Sponsorship Program aims to provide financial assistance to
              deserving students, enabling them to access quality education and
              resources. Through your generous contributions, we can help bridge
              the gap for students in need and foster a brighter future for all.
            </p>
          </div>

          {/* How It Works Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <h2 className="text-2xl font-semibold mb-4 flex items-center">
              <FaHandsHelping className="text-blue-600 mr-2" />
              How It Works
            </h2>
            <ul className="text-gray-700 list-disc list-inside leading-relaxed space-y-2">
              <li>
                1. Sign up as a sponsor by filling out our sponsorship form.
              </li>
              <li>2. Choose the level of support you wish to provide.</li>
              <li>
                3. Receive regular updates on the progress of your sponsored
                student.
              </li>
              <li>
                4. Join us for special events to meet the students and see the
                impact of your contribution.
              </li>
            </ul>
          </div>

          {/* Impact Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <h2 className="text-2xl font-semibold mb-4">
              The Impact of Your Support
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Your sponsorship will directly contribute to a student's
              education, covering tuition fees, learning materials, and
              extracurricular activities. Together, we can help shape the
              leaders of tomorrow.
            </p>
          </div>

          {/* Call to Action Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12 text-center">
            <h2 className="text-2xl font-semibold mb-4">Get Involved</h2>
            <p className="text-gray-700 leading-relaxed mb-6">
              Ready to make a difference? Join our sponsorship program today!
            </p>
            <button
              onClick={toggleModal}
              className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300"
            >
              Become a Sponsor
            </button>
          </div>
        </div>
      </div>

      {/* Modal for Sponsorship Form */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Sponsorship Form</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700" htmlFor="name">
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700" htmlFor="supportLevel">
                  Level of Support
                </label>
                <select
                  id="supportLevel"
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">Select...</option>
                  <option value="monthly">Monthly Contribution</option>
                  <option value="one-time">One-time Contribution</option>
                </select>
              </div>
              <button
                type="submit"
                className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-300"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={toggleModal}
                className="text-red-500 mt-2"
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Footer Component */}
      <Footer />
    </div>
  );
};

export default SponsorPage;
