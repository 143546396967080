import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AboutUs from "../components/AboutUs";
import Mission from "../components/Mission";
import Vision from "../components/Vision";
import Services from "../components/Services";
import Testimonials from "../components/Testimonials";
import ContactUs from "../components/ContactUs";
import "../styles/App.css";

// Import your local images
import schoolImg1 from "../images/school_img_1.jpg";
import schoolImg2 from "../images/school_img_2.jpg";
import schoolImg3 from "../images/school_img_3.jpg";

const HomePage = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section with Carousel */}
      <section className="relative h-screen overflow-hidden">
        <Carousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          className="h-full"
        >
          <div>
            <img
              src={schoolImg1}
              alt="Slide 1"
              className="object-cover h-full w-full"
            />
          </div>
          <div>
            <img
              src={schoolImg2}
              alt="Slide 2"
              className="object-cover h-full w-full"
            />
          </div>
          <div>
            <img
              src={schoolImg3}
              alt="Slide 3"
              className="object-cover h-full w-full"
            />
          </div>
        </Carousel>

        {/* Hero Section Text Overlay */}
        <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black bg-opacity-60 text-white p-4">
          <h1 className="text-5xl md:text-7xl font-bold mb-4 drop-shadow-lg">
            Welcome to{" "}
            <span className="text-yellow-400">Ogun Science Academy</span>
          </h1>
          <p className="text-xl md:text-3xl mb-8 drop-shadow-lg">
            Empowering Students for a Bright Future
          </p>
          <button className="bg-yellow-500 text-white px-8 py-4 rounded-lg hover:bg-yellow-600 transition duration-300 shadow-md">
            Learn More
          </button>
        </div>
      </section>

      {/* Main Content Section */}
      <main className="container mx-auto px-4 py-12">
        {/* About Us Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-bold text-center mb-4 text-green-700">
            <span className="text-yellow-500">OGUN SCIENCE ACADEMY</span> - The
            Hope Of Africa!
          </h2>
          <p className="text-lg leading-relaxed text-gray-700 mb-4">
            "The tide of Africa's brain drain must be reversed by creating a
            world-class education and research infrastructure that will keep the
            best minds on the continent and attract new ones."
          </p>
          <p className="text-lg leading-relaxed text-gray-700 mb-4">
            Bridging the skills deficiency gap in science, technology, and
            innovation is vital to unlocking Africa's potentials and
            accelerating economic growth and prosperity to save time, life, and
            the future of African children in the present decaying world of
            education in Nigeria and beyond.
          </p>
          <AboutUs />
        </section>

        {/* Mission and Vision Sections */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12">
          <section className="bg-white shadow-md rounded-lg p-6 border-t-4 border-green-700">
            <Mission />
          </section>
          <section className="bg-white shadow-md rounded-lg p-6 border-t-4 border-green-700">
            <Vision />
          </section>
        </div>

        {/* Services Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6 border-t-4 border-yellow-500">
          <Services />
        </section>

        {/* Testimonials Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6 border-t-4 border-green-700">
          <Testimonials />
        </section>

        {/* Contact Us Section */}
        <section className="my-12 bg-white shadow-md rounded-lg p-6 border-t-4 border-yellow-500">
          <h2 className="text-4xl font-bold text-center mb-6 text-green-700">
            Get in Touch
          </h2>
          <ContactUs />
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;
