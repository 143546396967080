// src/components/AboutUs.js
import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="relative py-12 bg-gradient-to-r from-green-100 via-teal-100 to-blue-100">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: "url('/images/ogun_science_academy_bg.jpg')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 py-12 text-center z-10">
        <h1 className="text-5xl font-bold text-white mb-6 animate__animated animate__fadeInDown animate__delay-1s">
          About Ogun Science Academy
        </h1>
        <p className="text-lg mb-8 animate__animated animate__fadeInUp animate__delay-2s text-white">
          Welcome to{" "}
          <span className="font-semibold text-yellow-300">
            Ogun Science Academy
          </span>
          , a hub for future innovators and problem solvers. Our goal is to
          cultivate curiosity, inspire discovery, and empower students to be the
          changemakers of tomorrow.
        </p>

        <div className="mb-12 animate__animated animate__fadeInLeft animate__delay-3s">
          <h2 className="text-2xl font-semibold text-yellow-300 mb-4">
            Our Vision
          </h2>
          <p className="text-lg text-white">
            At Ogun Science Academy, we envision a world where knowledge drives
            sustainable development, and education becomes the foundation for
            innovative solutions. We aim to inspire students to think critically
            and creatively to tackle the challenges of our ever-changing world.
          </p>
        </div>

        <div className="mb-12 animate__animated animate__fadeInRight animate__delay-4s">
          <h2 className="text-2xl font-semibold text-green-300 mb-4">
            Who We Are
          </h2>
          <p className="text-lg text-white">
            Ogun Science Academy is home to a dynamic learning environment that
            fosters academic excellence, character development, and lifelong
            learning. Our educators are dedicated to guiding students toward
            achieving their highest potential.
          </p>
        </div>

        <div className="mb-12 animate__animated animate__fadeInUp animate__delay-5s">
          <h2 className="text-2xl font-semibold text-blue-300 mb-4">
            Our Core Values
          </h2>
          <ul className="list-disc list-inside text-lg text-white space-y-2">
            <li>
              <span className="font-semibold">Innovation:</span> We encourage
              creativity and forward-thinking in all our students.
            </li>
            <li>
              <span className="font-semibold">Excellence:</span> A commitment to
              achieving the highest standards in education and personal growth.
            </li>
            <li>
              <span className="font-semibold">Integrity:</span> Building a
              foundation of trust, respect, and responsibility.
            </li>
          </ul>
        </div>

        <div className="animate__animated animate__fadeIn animate__delay-6s">
          <h2 className="text-2xl font-semibold text-red-300 mb-4">
            Our Mission
          </h2>
          <p className="text-lg text-white mb-6">
            We strive to provide a rigorous academic program that not only
            emphasizes science and technology but also prepares our students to
            be leaders and change agents in a global society.
          </p>
          <Link
            to="/learn-more"
            className="inline-block px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-indigo-700 hover:scale-105 transition duration-300 transform"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
