// src/components/Vision.js
import React from "react";
import { FaGlobe, FaTrophy } from "react-icons/fa";

const Vision = () => {
  return (
    <section id="vision" className="py-12 bg-white">
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-gray-800 mb-8">
          <span className="text-blue-600">Our </span>
          <span className="text-green-600">Vision</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-10">
          <div className="bg-blue-100 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-4">
              <FaGlobe className="text-6xl text-blue-500" />
            </div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
              Enabling Environment
            </h3>
            <p className="text-gray-700">
              We aim to create an enabling environment where science befriends
              and transforms the minds of millions.
            </p>
          </div>

          <div className="bg-green-100 p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-4">
              <FaTrophy className="text-6xl text-green-500" />
            </div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-2">
              Academic Excellence
            </h3>
            <p className="text-gray-700">
              Our vision promotes interest, learning, and creativity in science
              and technology through imaginative and enjoyable experiences.
            </p>
          </div>
        </div>

        <p className="text-gray-700 mb-4 leading-relaxed max-w-2xl mx-auto">
          We aspire to contribute to the nation's development of its human
          resources, fostering innovative thinkers and future leaders.
        </p>
        <p className="text-gray-700 mb-4 leading-relaxed max-w-2xl mx-auto">
          Together, we can inspire a generation equipped to face the challenges
          of tomorrow with curiosity and determination.
        </p>
      </div>
    </section>
  );
};

export default Vision;
